import { MS_PAYMENTS_URL } from "../../env"
import { getTenant } from "../../utils/getTenant"
import { getSession } from "../auth/getSession"

export type PlanCreatePayload = {
  type: string
  price_cents: number
  active: boolean
  interval: string
  description: string
  interval_count: number
  trial_period_days: number
  fee_paid_by_member: boolean
}

export const planCreate = async (payload: PlanCreatePayload) => {
  const { access_token } = await getSession()

  const body = { tenant: getTenant(), ...payload }

  const response = await fetch(`${MS_PAYMENTS_URL}/create-plan`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })

  if (!response.ok) throw new Error("Error creating plan")
}

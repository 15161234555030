import { supabase } from "../../lib/supabase"
import { SortType } from "../../types/sort"

export type SortRebalancePositionPayload = {
  kind: string
  tenant: string
}

export const needRebalance = (
  newPosition: number,
  prevPosition: number,
  nextPosition: number
) =>
  newPosition + 1 === prevPosition ||
  newPosition - 1 === nextPosition ||
  newPosition === prevPosition ||
  newPosition === nextPosition

export const sortRebalancePosition = async (
  payload: SortRebalancePositionPayload
) => {
  const { data } = await supabase
    .from("sort")
    .select("*, id::text")
    .eq("tenant", payload.tenant)
    .eq("kind", payload.kind)
    .returns<SortType[]>()

  if (!data) return

  const sortRecoredsPositionAsc = data.sort(
    (sortA, sortB) => sortA.position - sortB.position
  )

  await supabase.from("sort").upsert(
    sortRecoredsPositionAsc.map((sort, index) => ({
      ...sort,
      position: (index + 1) * 1000000000
    }))
  )
}

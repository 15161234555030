import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"
import { parsePost } from "../../utils/parsePost"
import { PostModel, PostType } from "../../types/post"
import { createInfiniteService } from "../../utils/createInfiniteService"

export type PostListPayload = {
  channelId?: string
}

export const postList = createInfiniteService<PostListPayload, PostModel>(
  async ({ rangeEnd, rangeStart, channelId }) => {
    const userId = await getUserId()
    let query = supabase
      .from("db")
      .select(
        `
      id::text,
      data,
      kind,
      member(user_uuid, data, role),
      user_uuid,
      saved:db!parent_id(id::text),
      liked:db!parent_id(id::text),
      likesCount:db!parent_id(count),
      commentsCount:db!parent_id(count),
      comments:db!parent_id(
        id::text,
        user_uuid,
        data->content,
        postId:parent_id::text,
        member(data->name,data->avatar,data->status),
        repliesCount:db!parent_id(count),
        likesCount:db!parent_id(count),
        likes:db!parent_id(id::text)
      ),
      channel:parent_id!inner(
        id::text,
        kind,
        data
      )
    `,
        { count: "exact", head: false }
      )
      .eq("tenant", getTenant())
      .in("kind", [KINDS.POST, KINDS.POST_FIXED])
      .eq("saved.kind", KINDS.POST_SAVED)
      .eq("saved.user_uuid", userId)
      .eq("liked.kind", KINDS.POST_LIKE)
      .eq("likesCount.kind", KINDS.POST_LIKE)
      .eq("liked.user_uuid", userId)
      .eq("commentsCount.kind", KINDS.POST_COMMENT)
      .eq("comments.kind", KINDS.POST_COMMENT)
      .eq("comments.repliesCount.kind", KINDS.COMMENT_REPLY)
      .eq("comments.likesCount.kind", KINDS.COMMENT_LIKE)
      .eq("comments.likes.kind", KINDS.COMMENT_LIKE)
      .eq("comments.likes.user_uuid", userId)
      .eq("channel.kind", KINDS.CHANNEL)
      .order("kind", { ascending: false })
      .limit(1, { foreignTable: "comments" })
      .order("id", { foreignTable: "comments" })
      .order("kind", { ascending: false })
      .range(rangeStart, rangeEnd)

    if (channelId) {
      query = query.eq("channel.id", channelId)
    }

    query = query.order("id")

    const { data, error, count } = await query

    const posts = (data as unknown) as PostType[] | null

    if (error || !posts) throw new Error("Posts not found")

    const handleParsePost = (post: PostType) => {
      const isFixed = post.kind === KINDS.POST_FIXED && Boolean(channelId)
      return parsePost(post, isFixed)
    }

    const parsedPosts = posts.map(handleParsePost)

    return {
      data: parsedPosts,
      error,
      count
    }
  }
)

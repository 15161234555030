import { memo } from "react"
import { Aside } from "@mantine/core"
import { DsStackVertical, DsText, createStyles, rem } from "@raisesistemas/ds"
import { NotificationsLatest } from "../Notifications/NotificationsLatest"

import { capitalize } from "../../utils/capitalize"
import { useTranslation } from "react-i18next"

export const NavRight = memo(() => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  return (
    <Aside
      fixed
      hidden={true}
      withBorder={false}
      width={{ lg: 320 }}
      hiddenBreakpoint="lg"
      data-testid="nav-right"
    >
      <DsStackVertical spacing={24} className={classes.container}>
        <DsText weight="bold" variant="body-1">
          {capitalize(t("common.recent", { type: t("root.activities") }))}
        </DsText>

        <NotificationsLatest />
      </DsStackVertical>
    </Aside>
  )
})

const useStyles = createStyles({
  container: {
    padding: rem(20)
  }
})

import { useForm } from "@mantine/form"
import {
  DsButtonPrimary,
  DsButtonSecondary,
  DsSpace,
  DsStackHorizontal,
  DsText,
  useDsTheme,
  toast,
  DsTextInputDocument,
  DsBox
} from "@raisesistemas/ds"

import { onlyNumbers } from "../utils/onlyNumbers"
import { usePlanFindQuery } from "../hooks/queries/usePlanQueries"
import { checkoutUrl } from "../api/checkout/checkoutUrl"
import { modals } from "@mantine/modals"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { formatMoneyInCents } from "../utils/formatMoneyInCents"

export const PreCheckoutContent = () => {
  const { t } = useTranslation()
  const { colors, colorScheme } = useDsTheme()
  const { data } = usePlanFindQuery()
  const [isLoading, setIsLoading] = useState(false)

  const textColor = colorScheme === "dark" ? colors.dark[1] : colors.gray[7]

  const price_cents = data?.data.price_cents || 0

  const { values, getInputProps, validate } = useForm({
    initialValues: {
      document: ""
    },
    validate: {
      document: value =>
        onlyNumbers(value).length === 11 ? null : "CPF inválido"
    }
  })

  const handleRequestAccess = async () => {
    if (validate().hasErrors) return

    setIsLoading(true)

    try {
      const { checkout_url } = await checkoutUrl(onlyNumbers(values.document))

      window.open(checkout_url, "_blank")
      modals.close("pre-checkout")
    } catch (error) {
      toast.error({ message: t("error.message.cant_finalize") }) // FEITO
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <DsBox data-testid={PRE_CHECKOUT_CONTENT_ID}>
      <DsText variant="body-2" weight="regular" color={textColor}>
        {t("modal.pre_checkout.description")}
      </DsText>

      <DsSpace height={8} />

      <DsText variant="body-2" weight="regular" color={textColor}>
        {t("modal.pre_checkout.price_description", {
          price: formatMoneyInCents(price_cents)
        })}
      </DsText>

      <DsSpace height={24} />

      <DsTextInputDocument
        required
        onlyCPF
        label="CPF"
        description={t("modal.pre_checkout.cpf_description")}
        inputWrapperOrder={["label", "input", "error", "description"]}
        {...getInputProps("document")}
      />

      <DsSpace height={24} />

      <DsStackHorizontal spacing="md" justify="end">
        <DsButtonSecondary onClick={() => modals.close("pre-checkout")}>
          {t("root.close")}
        </DsButtonSecondary>
        <DsButtonPrimary onClick={handleRequestAccess} loading={isLoading}>
          {t("root.go_to_payment")}
        </DsButtonPrimary>
      </DsStackHorizontal>
    </DsBox>
  )
}

export const PRE_CHECKOUT_CONTENT_ID = "pre-checkout-content-id"

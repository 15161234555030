import { Draggable, DraggableStateSnapshot } from "react-beautiful-dnd"

export type ItemProps = {
  draggableId: string
  index: number
  isDragDisabled: boolean
  children: (snapshot?: DraggableStateSnapshot) => React.ReactNode
}

export const ItemDraggable = (props: ItemProps) => {
  return (
    <Draggable
      key={props.draggableId}
      draggableId={props.draggableId}
      index={props.index}
      isDragDisabled={props.isDragDisabled}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {props.children(snapshot)}
        </div>
      )}
    </Draggable>
  )
}

import { DsNavbar, rem } from "@raisesistemas/ds"
import { createStyles } from "@raisesistemas/ds"
import { NavHeader } from "./NavHeader"
import { NavLinks } from "./NavLinks"
import { MenuUser } from "../MenuUser/MenuUser"
import { ChannelTopics } from "../Channel/ChannelTopics"

import { useTrial } from "../../hooks/useTrial"
import { useNavigate } from "react-router-dom"
import { useCallback } from "react"

export const Nav = () => {
  const { showBanner } = useTrial()
  const { cx, classes } = useStyles()
  const navigate = useNavigate()
  const handleNavigate = useCallback((path: string) => navigate(path), [])

  return (
    <DsNavbar
      width={{ sm: 350 }}
      className={cx(classes.root, {
        [classes.rootTrial]: Boolean(showBanner)
      })}
      hiddenBreakpoint="md"
      hidden
    >
      <NavHeader handleNavigate={handleNavigate} />
      <NavLinks handleNavigate={handleNavigate} />
      <ChannelTopics />
      <MenuUser />
    </DsNavbar>
  )
}

const useStyles = createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: rem(8),
    zIndex: 400,
    paddingTop: 0,
    paddingLeft: 16,
    paddingRight: 15,
    top: 0,
    height: "100vh",
    border: "none",
    padding: "1rem"
  },
  rootTrial: {
    top: "var(--trial-height)",
    height: "calc(100vh - var(--trial-height))"
  }
})

import {
  DsButtonDestructive,
  DsButtonSecondary,
  DsGroup,
  DsSpace,
  DsText,
  createStyles,
  toast,
  useDsTheme
} from "@raisesistemas/ds"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { useTopicMutations } from "../../../hooks/mutations/useTopicMutations"
import { modals } from "@mantine/modals"

export type ModalTopicDeleteProps = {
  topicId: string
}

export const MODAL_TOPIC_DELETE_ID = "modal-topic-delete"

export const ModalTopicDelete = (props: ModalTopicDeleteProps) => {
  const { t } = useTranslation()
  const { colors } = useDsTheme()
  const { classes } = useStyles()

  const topicMutations = useTopicMutations()

  const close = () => modals.close(MODAL_TOPIC_DELETE_ID)

  const handleDeleteTopic = () => {
    topicMutations.delete.mutate(props, {
      onSuccess: () => {
        toast.success({ message: t("toast.channel_topic.deleted") })
        close()
      }
    })
  }

  return (
    <Fragment>
      <DsGroup>
        <DsText variant="body-2" color={colors.gray[6]} weight="regular">
          {t("modal.channel_topic.delete.subtitle")}
        </DsText>
      </DsGroup>
      <DsSpace height={16} />

      <DsGroup spacing={16} noWrap>
        <DsButtonSecondary className={classes.button} onClick={close}>
          {t("common.back")}
        </DsButtonSecondary>

        <DsButtonDestructive
          loading={topicMutations.delete.isPending}
          className={classes.button}
          onClick={handleDeleteTopic}
        >
          {t("button.delete")}
        </DsButtonDestructive>
      </DsGroup>
    </Fragment>
  )
}

const useStyles = createStyles({
  button: {
    width: "100%"
  }
})

import { modals } from "@mantine/modals"
import {
  MODAL_TOPIC_DELETE_ID,
  ModalTopicDelete,
  ModalTopicDeleteProps
} from "../components/Channel/ChannelTopics/ModalTopicDelete"
import { useChannelMutations } from "./mutations/useChannelMutations"
import { useSortMutations } from "./mutations/useSortMutations"
import { useTranslation } from "react-i18next"
import { DsText, createStyles, useDsTheme } from "@raisesistemas/ds"
import { SortType } from "../types/sort"
import { useEffect, useState } from "react"
import { ChannelType } from "../types/channel"
import { useTopicQueries } from "./queries/useTopicQueries"
import { KINDS } from "../lib/constants"
import { getTenant } from "../utils/getTenant"

type Channel = ChannelType & { sort: SortType }
type Topic = {
  id: string
  data: {
    title: string
  }
  sort: SortType
  channels: Channel[]
}

export type OpenModalTopicDeleteParams = Omit<
  ModalTopicDeleteProps,
  "handleClose"
> & {
  topicTtile: string
}

export const useTopicMove = () => {
  const { classes } = useStyles()
  const { colors } = useDsTheme()
  const { t } = useTranslation()

  const sortMutations = useSortMutations()
  const channelMutations = useChannelMutations()
  const topicQueries = useTopicQueries()
  const topicList = topicQueries.listWithChannels()

  const [topics, setTopics] = useState<Topic[] | null>(null)
  const [updatedAt, setUpdatedAt] = useState<number | null>(null)

  const topicsLoading = !topicList.isFetching
  const topicsOldest = topicList.dataUpdatedAt !== updatedAt

  const getMaxPosition = () => {
    return (
      (topicList.topics?.reduce((acc, item) => acc + item.sort.position, 0) ||
        0) + 1000000000000
    )
  }

  const move = (
    item: Topic | Channel,
    prevPosition: number,
    nextPosition: number
  ) => {
    sortMutations.updatePosition.mutate({
      targetSort: item.sort,
      between: {
        nextPosition,
        prevPosition
      }
    })
  }

  const moveForAnotherTopic = (
    item: Channel,
    prevPosition: number,
    nextPosition: number,
    parentItem: Topic
  ) => {
    channelMutations.updateTopic.mutate(
      {
        channelId: item.id,
        topicId: parentItem.id === "000" ? null : parentItem.id
      },
      {
        onSuccess: () => {
          sortMutations.updatePosition.mutate({
            targetSort: item.sort,
            between: {
              nextPosition,
              prevPosition
            }
          })
        }
      }
    )
  }

  const openModalTopicDelete = (props: ModalTopicDeleteProps) => {
    const topic = topics?.find(topic => topic.id === props.topicId)

    if (!topic) return

    modals.open({
      modalId: MODAL_TOPIC_DELETE_ID,
      centered: true,
      title: (
        <DsText variant="body-1" weight="medium">
          {t("modal.channel_topic.delete.title") + " "}
          <DsText
            variant="body-1"
            weight="medium"
            className={classes.modalTitle}
            color={colors.gray[5]}
          >
            "{topic.data.title}" ?
          </DsText>
        </DsText>
      ),

      children: <ModalTopicDelete topicId={props.topicId} />
    })
  }

  useEffect(() => {
    if (topicsLoading && topicsOldest) {
      setUpdatedAt(topicList.dataUpdatedAt)
      setTopics([
        {
          id: "000",
          sort: {
            position: getMaxPosition(),
            id: "0",
            kind: KINDS.TOPIC,
            tenant: getTenant()
          },
          channels: topicList.channelsWithoutTopic || [],
          data: {
            title: "ALL"
          }
        },
        ...(topicList.topics || [])
      ])
    }
  }, [topicList])

  return {
    move,
    moveForAnotherTopic,
    openModalTopicDelete,
    topics
  }
}

const useStyles = createStyles({
  modalTitle: {
    display: "inline"
  }
})

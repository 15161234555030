import {
  DsBox,
  DsCenter,
  DsDrawerProps,
  createStyles,
  DsDrawer,
  DsText
} from "@raisesistemas/ds"
import { BottomSheet, BottomSheetProps } from "../BottomSheet"
import { useIsScreen } from "../../hooks/useIsScreen"

type SheetProps = DsDrawerProps &
  Pick<BottomSheetProps, "adjustToContentHeight" | "footerHeight"> & {
    showOverlay?: boolean
    zIndex?: number
  } & {
    testId?: string
    hideFooter?: boolean
  }

export const Sheet = (props: SheetProps) => {
  const {
    showOverlay = true,
    zIndex,
    title,
    testId,
    hideFooter = false,
    footer,
    ...rest
  } = props

  const { cx, classes } = useStyles()
  const { isDesktop, isMobile } = useIsScreen()

  const sheetFooter = hideFooter ? null : footer

  return (
    <DsCenter>
      {isDesktop && (
        <DsBox
          className={cx({
            [classes.removeOverlay]: !showOverlay
          })}
          data-testid={testId}
        >
          <DsDrawer
            zIndex={zIndex}
            withOverlay={showOverlay}
            title={
              // TODO: adicionar a prop 'component' no DsText.
              <DsText variant="body-1" weight="semi-bold">
                {title}
              </DsText>
            }
            {...rest}
            footer={sheetFooter}
          />
        </DsBox>
      )}

      {isMobile && (
        <BottomSheet
          data-testid={testId}
          title={title}
          footer={sheetFooter}
          {...rest}
        />
      )}
    </DsCenter>
  )
}

const useStyles = createStyles({
  removeOverlay: {
    ".mantine-Overlay-root": {
      opacity: 0
    }
  }
})

import { DsSelectSearch, DsSelectSearchProps } from "@raisesistemas/ds"

import banks from "../../assets/data/banks.json"

type SelectBankProps = Omit<DsSelectSearchProps, "data"> & {
  withAsterisk?: boolean
}

type BankBRType = (typeof banks)[0]

export const SelectBank = (props: SelectBankProps) => {
  const bankParse = ({ code, name }: BankBRType) => ({
    value: name,
    label: `${code} - ${name}`
  })

  return <DsSelectSearch data={banks.map(bankParse)} {...props} />
}

import {
  DsButtonBadge,
  DsButtonSecondary,
  DsDrawer,
  DsGroup,
  DsSpace,
  DsStackVertical,
  DsText
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"

import { PostModel } from "../../types/post"
import { PostView } from "./PostView"
import { drawers } from "../../state"
import { useNav } from "../../hooks/useNav"

export type PostViewDrawerProps = {
  post: PostModel
}

export const PostViewDrawer = () => {
  const { isOpen, close, props } = drawers.post.view
  const { post } = props
  const { t } = useTranslation()
  const navigate = useNav()
  return (
    <DsDrawer
      opened={isOpen}
      onClose={close}
      title={
        <DsGroup>
          <DsText weight="bold" variant="body-1">
            {t("root.post_in")}
          </DsText>
          {post.channel && (
            <DsButtonBadge
              title={post.channel.data.title}
              emoji={post.channel.data.emoji}
              onClick={() => navigate(`/channels/${post.channel?.id}/posts`)}
            />
          )}
        </DsGroup>
      }
      footer={
        <DsGroup position="right">
          <DsButtonSecondary onClick={close}>Fechar</DsButtonSecondary>
        </DsGroup>
      }
    >
      <DsStackVertical>
        <PostView post={post} />
      </DsStackVertical>

      <DsSpace height={100} />
    </DsDrawer>
  )
}

import { supabase } from "../../lib/supabase"
import { PlanType } from "../../types/plan"
import { getTenant } from "../../utils/getTenant"

export const planList = async () => {
  const { data, error } = await supabase
    .from("db")
    .select("id::text, data")
    .eq("kind", "plan")
    .eq("tenant", getTenant())
    .returns<PlanType[]>()

  if (error) throw error

  return data
}

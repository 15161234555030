import {
  DsBadge,
  DsBox,
  DsButtonPrimary,
  DsButtonSecondary,
  DsDrawer,
  DsFlex,
  DsGroup,
  DsSpace,
  DsStackVertical,
  DsText
} from "@raisesistemas/ds"
import { JSONContent } from "@tiptap/core"
import { useTranslation } from "react-i18next"

import { useChannelsQuery } from "../../hooks/queries/useChannelsQuery"
import { usePostEditor } from "../../hooks/editor/usePostEditor"
import { usePostForm } from "../../hooks/usePostForm"
import { PostEditor } from "./PostEditor"
import { usePostMutations } from "../../hooks/mutations/usePostMutations"
import { ImagePreviewUpload } from "../ImagePreviewUpload"
import { useParams } from "react-router-dom"
import { drawers } from "../../state"
import { useCurrentChannel } from "../../hooks/useCurrentChannel"

export type PostCreateEditDrawerProps = {
  post?: {
    id: string
    thumbnail?: string
    title: string
    content: JSONContent
    channelId: string
  }
}

const INITIAL_CONTENT = "<h2 data-placeholder></h2><p data-placeholder></p>"

export function PostCreateEditDrawer() {
  const { isOpen, close, props } = drawers.post.createOrEdit
  const { channel } = useCurrentChannel()
  const title = channel?.data.title || ""
  const { post } = props

  const { id: defaultChannelId } = useParams()
  const channelId = post?.channelId ?? defaultChannelId

  const { t } = useTranslation()
  const form = usePostForm({ ...post, channelId })
  const { data: channelsData } = useChannelsQuery()
  const channels = channelsData || []

  const editor = usePostEditor({
    content: post?.content || INITIAL_CONTENT,
    onUpdate: ({ editor }) => {
      if (!editor) return

      if (editor.isEmpty) {
        return editor.commands.setNode("heading", { level: 2 })
      }

      const json = editor.getJSON()
      const title = json?.content?.[0].content?.[0].text
      form.setFieldValue("title", title || "")
      form.clearFieldError("content")
    }
  })
  const postMutations = usePostMutations()
  const content = editor?.getJSON() || {
    type: "doc",
    content: []
  }

  const getChannelTitle = (id: string) => {
    const channel = channels.find(channel => channel.id === id)
    return channel?.data.title || ""
  }

  const getCurrentChannelTitle = () => {
    if (post) return getChannelTitle(post.channelId)
    return title
  }

  const edit = postMutations.update

  const add = postMutations.create

  const isEdit = !!post?.id
  const isLoading = isEdit ? edit.isPending : add.isPending

  const handleEdit = () => {
    if (!post) return
    const { values } = form

    edit.mutate(
      {
        id: post.id,
        data: {
          thumbnail: values.thumbnail,
          title: values.title,
          content
        },
        channelId: values.channelId
      },
      { onSuccess: close }
    )
  }

  const handleAdd = () => {
    add.mutate(
      { ...form.values, content },
      {
        onSuccess: () => {
          form.reset()
          editor?.commands.setContent("")
          close()
        }
      }
    )
  }

  function submit() {
    form.validate()

    if (!editor) return

    const isInvalid =
      editor.isEmpty || editor.state.doc.childCount < 2 || !editor.getText()

    if (isInvalid) {
      return form.setFieldError("content", t("validation.required_field"))
    }

    if (form.isValid()) return isEdit ? handleEdit() : handleAdd()
  }

  const handleClose = () => {
    editor?.commands.setContent(post?.content || INITIAL_CONTENT)
    form.reset()
    close()
  }

  const footer = (
    <DsBox>
      <DsStackVertical>
        <DsFlex gap={8} align="center">
          <DsText variant="body-2" weight="medium">
            {t("input.post.create.linked_channel.label")}:{" "}
          </DsText>{" "}
          <DsBadge>{getCurrentChannelTitle()}</DsBadge>
        </DsFlex>

        <DsGroup position="right">
          <DsButtonSecondary onClick={close} disabled={isLoading}>
            {t("button.cancel")}
          </DsButtonSecondary>
          <DsButtonPrimary onClick={submit} loading={isLoading}>
            {isEdit ? t("button.edit") : t("button.create_post")}
          </DsButtonPrimary>
        </DsGroup>
      </DsStackVertical>
    </DsBox>
  )

  return (
    <DsDrawer
      size="xl"
      opened={isOpen}
      onClose={handleClose}
      title={
        <DsText variant="body-1" weight="bold">
          {isEdit ? t("root.edit") : t("root.create_post")}
        </DsText>
      }
      scroll
      footer={footer}
    >
      <DsStackVertical spacing="sm">
        <ImagePreviewUpload
          title={t("root.add_post_thumbnail")}
          fullPreview={true}
          fullWidth={true}
          aspectRatio={2.8}
          image={form.values.thumbnail}
          imageDimensionsLabel="840 x 300px"
          imageSizeLabel="10mb"
          handleImage={image => form.setFieldValue("thumbnail", image)}
        />

        <PostEditor editor={editor} error={form.errors?.content as string} />

        <DsSpace height={100} />
      </DsStackVertical>
    </DsDrawer>
  )
}

import { createQueryKeys } from "@lukemorales/query-key-factory"
import { planList } from "../api/plan/planList"
import { getTenant } from "../utils/getTenant"
import { planFind } from "../api/plan/planFind"

export const planQueries = createQueryKeys("plans", {
  list: () => ({
    queryKey: [getTenant()],
    queryFn: planList
  }),
  find: () => ({
    queryKey: [getTenant()],
    queryFn: planFind
  })
})

import { toast } from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { queries } from "../../queries"
import { bankUpdate } from "../../api/bank/bankUpdate"

export const useBankMutations = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const update = useMutation({
    mutationFn: bankUpdate,

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queries.banks.find._def })

      toast.success({
        message: t("common.updated", { type: t("root.bank_account") }),
        variant: "solid"
      })
    },
    onError: (message: string | null) => {
      if (message) return toast.info({ message })

      toast.error({
        message: t("error.message.cant_finalize")
      })
    }
  })

  return {
    update
  }
}

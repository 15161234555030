import { PropsWithChildren } from "react"
import { rem, DsDrawerProps, DsMediaQuery } from "@raisesistemas/ds"

import { PlanForm } from "./PlanForm"
import { BankForm } from "./BankForm"
import { usePlanStepper } from "./usePlanStepper"
import { Stepper } from "../../../components/common/Stepper"
import { Sheet } from "../../../components/Sheet"

export type PlanStepperDrawerProps = Pick<DsDrawerProps, "opened" | "onClose">

export function PlanStepperDrawer(
  props: PropsWithChildren<PlanStepperDrawerProps>
) {
  const { onClose, opened } = props

  const { step, planForm, bankForm, handleNavigate, handleSubmit, isLoading } =
    usePlanStepper()

  return (
    <Sheet opened={opened} onClose={onClose} title="">
      <DsMediaQuery largerThan="md" styles={{ padding: 0 }}>
        <Stepper.Root
          active={step}
          onNavigateTo={handleNavigate}
          onFinalize={handleSubmit}
          sx={{ padding: rem(24) }}
          isLoading={isLoading}
        >
          <Stepper.Step>
            <PlanForm form={planForm} />
          </Stepper.Step>
          <Stepper.Step>
            <BankForm form={bankForm} />
          </Stepper.Step>
        </Stepper.Root>
      </DsMediaQuery>
    </Sheet>
  )
}

import { useForm } from "@mantine/form"
import { JSONContent } from "@tiptap/core"
import { useTranslation } from "react-i18next"

import { useAuth } from "./useAuth"

type InitialValues = {
  thumbnail?: string
  title?: string
  content?: JSONContent
  channelId?: string
}

export const usePostForm = (initialValues?: InitialValues) => {
  const { user } = useAuth()
  const { t } = useTranslation()

  const form = useForm({
    initialValues: {
      thumbnail: initialValues?.thumbnail || "",
      title: initialValues?.title || "",
      content: initialValues?.content || "",
      channelId: initialValues?.channelId || "",
      user_uuid: user!.user_uuid
    },

    validate: {
      channelId: value =>
        value.length < 1 ? t("validation.channel_min_requisite") : null
    }
  })

  return form
}

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { memberAddRole } from "../../api/member/memberAddRole"
import { queries } from "../../queries"
import { memberEdit } from "../../api/member/memberEdit"
import { useImageUpload } from "../useImageUpload"
import { FormEditMember } from "../../pages/settings/PageSettingsProfile"
import { toast } from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"
import { memberDeleteAccount } from "../../api/member/memberDeleteAccount"
import { memberFinishRegister } from "../../api/member/memberFinishRegister"
import { useAuth } from "../useAuth"
import { normalizeURL } from "../../utils/normalizeUrl"
import { memberRemove } from "../../api/member/memberRemove"
import { useUser } from "../useUser"
import { getTenant } from "../../utils/getTenant"

export const useMemberMutations = () => {
  const { user_uuid } = useUser()
  const { setUser } = useAuth()
  const queryClient = useQueryClient()
  const { handleUploadImage } = useImageUpload()
  const { t } = useTranslation()

  const updateRole = useMutation({
    mutationFn: memberAddRole,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: queries.members._def
      })
    }
  })

  const edit = useMutation({
    mutationFn: async (payload: FormEditMember) => {
      const newAvatar = await handleUploadImage(payload.avatar, {
        bucket: "files",
        folder: `${getTenant()}/${user_uuid}`,
        fileName: "profile.png"
      })
      const avatar = newAvatar ? newAvatar : payload.avatar
      const data = Object.assign({}, payload, {
        avatar,
        instagram: normalizeURL(payload.instagram),
        linkedin: normalizeURL(payload.linkedin),
        site: normalizeURL(payload.site)
      })
      return await memberEdit(data)
    },
    onSuccess({ member }) {
      setUser(member)
      toast.success({
        message: t("validation.saved_successfully")
      })
    }
  })

  const deleteAccount = useMutation({
    mutationFn: memberDeleteAccount
  })

  const finishRegister = useMutation({
    mutationFn: memberFinishRegister,
    onError() {
      toast.error({ message: t("error.message.cant_finalize") })
    },
    onSuccess({ user }) {
      toast.success({
        message: t("common.updated", { type: t("common.register") })
      })

      if (user) setUser(user)
    }
  })

  const remove = useMutation({
    mutationFn: memberRemove,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: queries.members._def })
    }
  })

  return {
    remove,
    updateRole,
    edit,
    deleteAccount,
    finishRegister
  }
}

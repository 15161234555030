import { supabase } from "../../lib/supabase"
import { MemberRole } from "../../types/member"
import { getTenant } from "../../utils/getTenant"
import { subscriptionCancel } from "../subscription/subscriptionCancel"
import { subscriptionFindUnique } from "../subscription/subscriptionFindUnique"

export type MemberAddRolePayload = {
  id: string
  role: MemberRole

  tenant?: string
}

export async function memberAddRole(payload: MemberAddRolePayload) {
  const { id, role, tenant = getTenant() } = payload

  const { error } = await supabase
    .from("member")
    .update({ role })
    .eq("user_uuid", id)
    .eq("tenant", tenant)

  if (error) throw new Error(error.message)

  subscriptionFindUnique({ memberId: id }).then(subscription => {
    const hasSubscriptionActive = subscription?.data.active

    const isBlockedMemberNow = role === "blocked_member"
    const isModeratorNow = role === "moderator"

    if (hasSubscriptionActive && (isBlockedMemberNow || isModeratorNow)) {
      subscriptionCancel({ memberId: id })
    }
  })
}

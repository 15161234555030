import { MS_PAYMENTS_URL } from "../../env"
import { getTenant } from "../../utils/getTenant"
import { getSession } from "../auth/getSession"

export const checkoutUrl = async (document: string) => {
  const { access_token, user } = await getSession()
  const { email, user_metadata } = user
  const name = user_metadata?.name

  const query = encodeURI(
    `?tenant=${getTenant()}&name=${name}&email=${email}&document=${document}`
  )

  const response = await fetch(`${MS_PAYMENTS_URL}/checkout${query}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  })

  if (!response.ok) throw new Error(response.statusText)

  return (await response.json()) as { checkout_url: string }
}

import { useId } from "react"
import {
  InfiniteData,
  useMutation,
  useQueryClient
} from "@tanstack/react-query"
import { toast } from "@raisesistemas/ds"
import parseISO from "date-fns/parseISO"
import compareAsc from "date-fns/compareAsc"
import { useTranslation } from "react-i18next"

import { useUser } from "../useUser"
import { drawers } from "../../state"
import { queries } from "../../queries"
import { EventType } from "../../types/event"
import { getTenant } from "../../utils/getTenant"
import { STORAGE_KEYS } from "../../lib/constants"
import { capitalize } from "../../utils/capitalize"
import { InfinitePageType } from "../../types/shared"
import { eventDelete } from "../../api/events/eventDelete"
import { eventUpdate } from "../../api/events/eventUpdate"
import { eventCreate } from "../../api/events/eventCreate"
import { normalizeURL } from "../../utils/normalizeUrl"

export const useEventMutations = () => {
  const { t } = useTranslation()
  const { user_uuid } = useUser()
  const queryClient = useQueryClient()

  const queryKey = queries.events.list({ type: "next" }).queryKey

  const id = useId()

  const create = useMutation({
    mutationFn: eventCreate,

    onMutate: async variables => {
      drawers.event.handler.close()

      await queryClient.cancelQueries({ queryKey })

      const previousEvents = queryClient.getQueryData(queryKey)

      const data = Object.assign({}, variables, {
        link: normalizeURL(variables.link)
      })

      const handler = (old?: InfiniteData<InfinitePageType<EventType>>) => {
        const record: EventType = {
          id, // faker id
          kind: "event",
          user_uuid,
          data,
          tenant: getTenant(),
          parent: null,
          child_id: null
        }

        if (!old) {
          const firstPage = {
            data: [record],
            nextId: null,
            previousId: null
          }

          return {
            pageParams: [],
            pages: [firstPage]
          }
        }

        const firstPage = {
          ...old.pages[0],
          data: [record, ...old.pages[0].data]
        }

        const _pages = [firstPage, ...old.pages.slice(1)]

        const pages = _pages.map(item => {
          return {
            ...item,
            data: item.data.sort((eventA, eventB) =>
              compareAsc(
                parseISO(eventA.data.start_date),
                parseISO(eventB.data.start_date)
              )
            )
          }
        })

        return { ...old, pages }
      }

      queryClient.setQueryData<InfiniteData<InfinitePageType<EventType>>>(
        queryKey,
        handler
      )

      toast.info({
        id,
        message: t("common.creating", { type: t("root.event").toLowerCase() }),
        loading: true,
        autoClose: false
      })

      return { previousEvents }
    },

    onSuccess: () => {
      toast.update({
        id,
        type: "success",
        message: capitalize(t("common.added", { type: t("root.event") }))
      })

      localStorage.removeItem(STORAGE_KEYS.EVENT_CREATE_DATA)
    },

    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context?.previousEvents)

      toast.update({
        id,
        type: "error",
        message: capitalize(
          t("common.failed_to_create", {
            type: t("root.event")
          })
        )
      })
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queries.events._def })
    }
  })

  const trash = useMutation({
    mutationFn: eventDelete,
    onSuccess: () => {
      toast.success({
        message: t("common.deleted", { type: t("root.event") })
      })
      queryClient.invalidateQueries({ queryKey: queries.events.list._def })
      queryClient.invalidateQueries({
        queryKey: queries.events.inProgress().queryKey
      })
    },
    onError: () => {
      toast.error({
        message: t("common.failed_to_delete", {
          type: t("root.event").toLowerCase()
        })
      })
    }
  })

  const update = useMutation({
    mutationFn: eventUpdate,
    onMutate: async variables => {
      const data = Object.assign({}, variables, {
        link: normalizeURL(variables.link)
      })
      return { data }
    },
    onSuccess: () => {
      toast.success({
        message: t("common.updated", { type: t("root.event") })
      })
      queryClient.invalidateQueries({ queryKey: queries.events.list._def })
      queryClient.invalidateQueries({
        queryKey: queries.events.inProgress().queryKey
      })
    },
    onError: () => {
      toast.error({
        message: t("common.failed_to_edit", {
          type: t("root.event").toLowerCase()
        })
      })
    }
  })

  return {
    create,
    trash,
    update
  }
}

import { supabase } from "../../lib/supabase"
import { SortType } from "../../types/sort"
import { getTenant } from "../../utils/getTenant"
import { needRebalance, sortRebalancePosition } from "./sortRebalancePosition"

export type SortUpdatePositionPayload = {
  targetSort: SortType
  between: {
    prevPosition: number
    nextPosition: number
  }
}

export const sortUpdatePosition = async (
  payload: SortUpdatePositionPayload
) => {
  const { targetSort, between } = payload

  const newPosition = getNewPosition(between.prevPosition, between.nextPosition)
  const isNeedMakeRebalance = needRebalance(
    newPosition,
    between.prevPosition,
    between.nextPosition
  )
  const { error } = await supabase
    .from("sort")
    .update({
      position: newPosition
    })
    .eq("id", targetSort.id)
    .eq("kind", targetSort.kind)

  if (isNeedMakeRebalance)
    await sortRebalancePosition({
      kind: targetSort.kind,
      tenant: getTenant()
    })
  if (error) throw new Error(error.message)
}

const getNewPosition = (prevPosition: number, nextPosition: number): number => {
  return Math.round((prevPosition + nextPosition) / 2)
}

import { useMutation, useQueryClient } from "@tanstack/react-query"

import { queries } from "../../queries"
import { sortUpdatePosition } from "../../api/sort/sortUpdatePosition"
import { useStandardToast } from "../useStandardToast"

export const useSortMutations = () => {
  const queryClient = useQueryClient()
  const { cantFinalize } = useStandardToast()

  const updatePosition = useMutation({
    mutationFn: sortUpdatePosition,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.topic.listWithChannels._def
      })
    },
    onError: () => {
      cantFinalize()
    }
  })

  return {
    updatePosition
  }
}

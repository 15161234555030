import { DsGroup, rem } from "@raisesistemas/ds"
import { createRandomId } from "../../utils/createRandomId"
import { Skeleton } from "@mantine/core"

export const BalanceSkeleton = () => {
  return (
    <DsGroup align="center" key={createRandomId()}>
      <Skeleton height={16} radius="lg" style={{ maxWidth: rem(200) }} />
      <Skeleton height={40} radius="lg" />
    </DsGroup>
  )
}

import { signal } from "@preact/signals-react"
import { MemberDetailDrawerProps } from "../components/Member/MemberDetail/MemberDetailDrawer"
import { EventViewDrawerProps } from "../components/Events/EventViewDrawer"
import { EventHandlerDrawerProps } from "../components/Events/EventHandlerDrawer"
import { ExtractSimpleDrawerProps } from "../components/ExtractDrawer/ExtractSimpleDrawer"
import { PostCreateEditDrawerProps } from "../components/post/PostCreateEditDrawer"
import { PostViewDrawerProps } from "../components/post/PostViewDrawer"
import { ChannelCreateDrawerProps } from "../components/Channel/ChannelCreateDrawer"
import { ChannelTopicEditDrawerProps } from "../components/Channel/ChannelTopicEditDrawer"
import { ChannelEditDrawerProps } from "../components/Channel/ChannelEditDrawer"
import { LikeListDrawerProps } from "../components/Like/LikeListDrawer"
import { MemberPermissionDrawerProps } from "../components/Member/MemberPermissionsDrawer"

export const isOpenedPlanDrawer = signal<boolean>(false)
export const isOpenedProModal = signal<boolean>(false)

const buildBaseDrawer = <T>(initialProps?: T) => {
  const state = signal(false)
  const appendInDom = signal(false)

  const _initialProps = Object.assign({}, initialProps)
  const _props = signal(_initialProps)

  const drawerState = {
    get appendDOM() {
      return appendInDom.value
    },
    get isOpen() {
      return state.value
    },
    open: (props?: T) => {
      if (props) _props.value = props
      appendInDom.value = true

      setTimeout(() => (state.value = true), 50)
    },
    close: () => {
      state.value = false

      setTimeout(() => {
        appendInDom.value = false
        _props.value = _initialProps
      }, 500)
    },

    get props() {
      return _props.value
    }
  }

  return drawerState
}

export const drawers = {
  bank: {
    accountInfo: buildBaseDrawer(),
    edit: buildBaseDrawer()
  },
  channel: {
    create: buildBaseDrawer<ChannelCreateDrawerProps>(),
    edit: buildBaseDrawer<ChannelEditDrawerProps>()
  },
  event: {
    handler: buildBaseDrawer<EventHandlerDrawerProps>(),
    detail: buildBaseDrawer<EventViewDrawerProps>()
  },
  extract: {
    full: buildBaseDrawer(),
    simple: buildBaseDrawer<ExtractSimpleDrawerProps>()
  },
  member: {
    detail: buildBaseDrawer<MemberDetailDrawerProps>(),
    inviteSelector: buildBaseDrawer(),
    inviteByCSV: buildBaseDrawer(),
    inviteByEmail: buildBaseDrawer(),
    permissions: buildBaseDrawer<MemberPermissionDrawerProps>()
  },
  notifications: {
    show: buildBaseDrawer(),
    manager: buildBaseDrawer(),
    latest: buildBaseDrawer()
  },
  post: {
    createOrEdit: buildBaseDrawer<PostCreateEditDrawerProps>(),
    view: buildBaseDrawer<PostViewDrawerProps>()
  },
  like: {
    list: buildBaseDrawer<LikeListDrawerProps>()
  },
  topic: {
    create: buildBaseDrawer(),
    edit: buildBaseDrawer<ChannelTopicEditDrawerProps>()
  }
}

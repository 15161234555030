import {
  DsActionIconDefault,
  DsBox,
  DsButtonUnstyled,
  DsGroup,
  DsMenu,
  DsStackVertical,
  DsText,
  createStyles,
  rem,
  DsTooltip
} from "@raisesistemas/ds"
import { IconChevronDown, IconPlus } from "@tabler/icons-react"
import { IconEllipsisHorizontal } from "../../../icons"
import { useToggle } from "@mantine/hooks"
import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { useUserPermissions } from "../../../hooks/useUserPermissions"
import { ChannelType } from "../../../types/channel"
import { ChannelCreateDrawerProps } from "../ChannelCreateDrawer"
import { ChannelTopicEditDrawerProps } from "../ChannelTopicEditDrawer"
import { ModalTopicDeleteProps } from "./ModalTopicDelete"

type TopicProps = {
  id: string
  title: string
  channels: ChannelType[]
  openChannelDrawer: (props?: ChannelCreateDrawerProps) => void
  openTopicCreateDrawer: () => void
  openTopicEditDrawer: (props: ChannelTopicEditDrawerProps) => void
  openModalTopicDelete: (props: ModalTopicDeleteProps) => void
}

export const Topic = (props: PropsWithChildren<TopicProps>) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()
  const [isChannelListOpen, toggleChannelList] = useToggle([true, false])
  const { CAN_SEE_NEW_CHANNEL_BUTTON } = useUserPermissions()

  const hasChannels = props.channels.length > 0
  const Header = () => {
    if (props.title === "ALL") return

    return (
      <DsGroup className={classes.header} position="apart" spacing={0}>
        <DsGroup align="center" spacing={8}>
          <DsTooltip label={props.title} multiline maxWidth={200}>
            <DsText
              variant="body-1"
              weight="semi-bold"
              className={classes.title}
            >
              {props.title}
            </DsText>
          </DsTooltip>

          <DsActionIconDefault
            radius="lg"
            size="sm"
            className={classes.unborder}
            onClick={() => toggleChannelList()}
          >
            <IconChevronDown
              size={14}
              className={cx(classes.arrow, {
                [classes.arrowRotate]: isChannelListOpen
              })}
            />
          </DsActionIconDefault>
        </DsGroup>

        {CAN_SEE_NEW_CHANNEL_BUTTON && (
          <DsGroup position="apart" align="center">
            <DsMenu
              target={
                <div>
                  <DsButtonUnstyled className={classes.button}>
                    <IconEllipsisHorizontal />
                  </DsButtonUnstyled>
                </div>
              }
              dropdownItens={[
                {
                  name: t("root.edit_group"),
                  icon: " ",
                  withDivider: true,
                  visible: true,
                  onClick: () =>
                    props.openTopicEditDrawer({ topicId: props.id })
                },
                {
                  name: t("root.delete_group"),
                  color: "red",
                  icon: " ",
                  withDivider: false,
                  visible: true,
                  onClick: () =>
                    props.openModalTopicDelete({
                      topicId: props.id
                    })
                }
              ]}
            />

            <DsMenu
              target={
                <div>
                  <DsButtonUnstyled className={classes.button}>
                    <IconPlus size={12} />
                  </DsButtonUnstyled>
                </div>
              }
              dropdownItens={[
                {
                  name: t("button.create_channel"),
                  icon: " ",
                  withDivider: false,
                  visible: true,
                  onClick: () => props.openChannelDrawer({ topicId: props.id })
                }
              ]}
            />
          </DsGroup>
        )}
      </DsGroup>
    )
  }

  return (
    <DsStackVertical spacing={0} className={classes.root}>
      <Header />

      <DsBox
        className={cx(classes.navBox, {
          [classes.navBoxShow]: isChannelListOpen && hasChannels
        })}
      >
        {props.children}
      </DsBox>
    </DsStackVertical>
  )
}

const useStyles = createStyles(({ fn, spacing, colorScheme, colors }) => ({
  root: {
    backgroundColor: colorScheme === "dark" ? colors.dark[7] : "#fff",
    position: "relative"
  },
  header: {
    padding: `8px ${spacing.xs}`
  },
  title: {
    maxWidth: rem(205),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [fn.smallerThan("sm")]: {
      maxWidth: rem(270)
    }
  },
  navBox: {
    position: "absolute",
    visibility: "hidden",
    inset: 0,
    width: "100%",
    height: "100%",
    zIndex: 10
  },
  navBoxShow: {
    position: "static",
    visibility: "visible"
  },

  button: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  unborder: {
    border: 0
  },
  arrow: {
    transition: ".1s linear"
  },
  arrowRotate: {
    transform: "rotate(180deg)"
  },
  arrows: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    top: 0,
    bottom: 0,
    right: rem(30),
    cursor: "move",
    opacity: 0
  }
}))

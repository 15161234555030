import { IconLock, IconUnlock } from "../icons"
import { ROLES } from "../lib/constants"
import { ChannelType } from "../types/channel"
import { useUser } from "./useUser"

export const useChannelLockIcon = () => {
  const { role } = useUser()

  const getChannelLockIcon = (channel: ChannelType) => {
    const { locked } = channel.data
    const isOwner = role === ROLES.OWNER
    const isMemberOrMemberPro = role === ROLES.MEMBER_PRO
    const showLock = isOwner || !isMemberOrMemberPro

    if (!locked) return null

    if (showLock) return <IconLock size={14} />
    return <IconUnlock size={14} />
  }

  return {
    getChannelLockIcon
  }
}

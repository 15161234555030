import { useMutation, useQueryClient } from "@tanstack/react-query"
import { planCreate } from "../../api/plan/planCreate"
import { queries } from "../../queries"
import { planAndBankCreate } from "../../api/plan/planAndBankCreate"
import { toast } from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"

export const usePlanMutations = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const create = useMutation({
    mutationFn: planCreate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queries.plans._def })
    }
  })

  const createPlanAndBank = useMutation({
    mutationFn: planAndBankCreate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queries.plans._def })
      toast.success({
        id: "create-plan-success",
        message: t("root.info_saved")
      })
    },
    onError: () => {
      toast.error({
        id: "create-plan-error",
        message: t("error.message.cant_finalize")
      })
    }
  })

  return {
    create,
    createPlanAndBank
  }
}

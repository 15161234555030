import { useForm } from "@mantine/form"
import { useTranslation } from "react-i18next"
import { planSchema } from "../../../validations/planSchema"

export const usePlanForm = () => {
  const { t } = useTranslation()
  const defaultValues = {
    type: "subscription",
    price_cents: "",
    active: true,
    interval: "months",
    description: "",
    interval_count: 1,
    trial_period_days: 0,
    fee_paid_by_member: false
  }

  return useForm({
    initialValues: defaultValues,
    validate: planSchema(t)
  })
}

export type PlanFormValues = ReturnType<typeof usePlanForm>["values"]

import { DsGroup, DsText, DsTooltip } from "@raisesistemas/ds"
import { PropsWithChildren } from "react"
import { IconOutlineInfo } from "../../icons"

type BalanceHeadingProps = PropsWithChildren<{
  title: string
  tooltipTitle: string
}>

export const BalanceHeading: React.FC<BalanceHeadingProps> = props => {
  const { title, tooltipTitle, children } = props

  return (
    <DsGroup position="apart">
      <DsGroup>
        <DsText variant="body-1" weight="medium">
          {title}
        </DsText>
        <DsTooltip label={tooltipTitle} maxWidth={310}>
          <IconOutlineInfo />
        </DsTooltip>
      </DsGroup>

      <DsGroup>{children}</DsGroup>
    </DsGroup>
  )
}

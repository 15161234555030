import { useTranslation } from "react-i18next"
import { DsStackVertical } from "@raisesistemas/ds"

import { drawers } from "../../../state"
import { ButtonSubtle } from "../../ButtonSubtle"
import { capitalize } from "../../../utils/capitalize"
import { NotificationItem } from "../NotificationItem"
import { NotificationEmpty } from "../NotificationEmpty"
import { NotificationsSkeleton } from "../../Skeletons/NotificationsSkeleton"
import { useActivitiesQueries } from "../../../hooks/queries/useActivitiesQueries"

export const NotificationsLatest = () => {
  const { t } = useTranslation()
  const { latest } = useActivitiesQueries()

  const { data, isLoading } = latest()

  const hasData = data && data.length > 0

  if (isLoading) return <NotificationsSkeleton />
  if (!hasData) return <NotificationEmpty />

  return (
    <DsStackVertical spacing={20}>
      {data.map(notification => (
        <NotificationItem
          key={`notifitcation-item-${notification.id}`}
          data={notification}
        />
      ))}

      <ButtonSubtle onClick={drawers.notifications.latest.open}>
        {capitalize(t("common.see", { type: t("root.activities") }))}
      </ButtonSubtle>
    </DsStackVertical>
  )
}

import { supabase } from "../../lib/supabase"
import { PostDataType } from "../../types/post"

export type PostUpdatePayload = {
  id: string
  data: PostDataType
  channelId: string
}

export async function postUpdate(payload: PostUpdatePayload) {
  const recordPost = {
    parent_id: payload.channelId,
    data: {
      thumbnail: payload.data.thumbnail,
      title: payload.data.title,
      content: payload.data.content
    }
  }

  const { error } = await supabase
    .from("db")
    .update(recordPost)
    .eq("id", payload.id)

  if (error) throw new Error(error.message)
}

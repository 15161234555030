import {
  DsBox,
  DsButtonPrimary,
  DsButtonSecondary,
  DsMediaQuery,
  DsSpace,
  DsStackVertical,
  DsTabs,
  DsText,
  useDsTheme
} from "@raisesistemas/ds"
import { ExtractHistory } from "./ExtractHistory"
import { useTranslation } from "react-i18next"
import { Sheet } from "../Sheet"
import { BalanceDisplay } from "../BalanceDisplay"
import { modals } from "@mantine/modals"
import { useExtractQueries } from "../../hooks/queries/useExtractQueries"
import { extractPresenter } from "../../presenter/extractPresenter"
import { usePlanFindQuery } from "../../hooks/queries/usePlanQueries"
import { useBankQueries } from "../../hooks/queries/useBankQueries"
import { useBalanceQueries } from "../../hooks/queries/useBalanceQueries"
import { drawers } from "../../state"

export const ExtractDrawer = () => {
  const { isOpen, close } = drawers.extract.full
  const { t } = useTranslation()
  const { colors } = useDsTheme()

  const extractQueries = useExtractQueries()
  const bankQueries = useBankQueries()
  const balanceQueries = useBalanceQueries()
  const extractEntries = extractQueries.ownerEntriesList()
  const extractExists = extractQueries.ownerExistsList()

  const { enableToRequestWithdraw } = balanceQueries.balanceOwner()

  const { data: plan } = usePlanFindQuery()

  const { data: bank } = bankQueries.find()

  const onConfirm = () => {
    if (!!plan) {
      return modals.open({
        title: false,
        withCloseButton: false,
        centered: true,
        size: 500,
        children: (
          <DsStackVertical spacing={16}>
            <DsText variant="body-2" weight="medium">
              {t("root.you_have_already_requested_the_deposit_to_your_account")}
            </DsText>

            <DsText variant="body-2" weight="regular" color={colors.gray[5]}>
              {`${t("root.the_deposit_period_for_your_account")} ${t(
                "root.please_wait_while_we_process_your_request"
              )}`}
            </DsText>

            <DsButtonPrimary onClick={() => modals.closeAll()}>
              {t("common.understood")}
            </DsButtonPrimary>
          </DsStackVertical>
        )
      })
    }
    drawers.bank.accountInfo.open()
    close()
  }

  return (
    <Sheet opened={isOpen} onClose={close} title={t("root.extract")}>
      <DsBox>
        <DsText variant="body-2" color={colors.gray[6]} weight="regular">
          {t("root.track_you_members_payments")}
        </DsText>
        <DsSpace height={24} />

        <BalanceDisplay showSmartButton={false} />

        <DsSpace height={24} />

        <DsTabs
          grow
          data={[
            {
              name: "entries",
              content: (
                <ExtractHistory
                  loading={extractEntries.isFetching}
                  historic={extractPresenter.entriesByDate(
                    extractEntries.historic
                  )}
                />
              ),
              title: t("root.entries"),
              isVisible: true
            },
            {
              name: "exits",
              content: (
                <ExtractHistory
                  historic={extractPresenter.existsByDate(
                    extractExists.historic,
                    bank?.data
                  )}
                />
              ),
              title: t("root.exits"),
              isVisible: true
            }
          ]}
          defaultTab="entries"
          variant="contained"
        />
        <DsSpace height={24} />
        <DsMediaQuery largerThan="md" styles={{ flexDirection: "row-reverse" }}>
          <DsStackVertical spacing={16}>
            {enableToRequestWithdraw && (
              <DsButtonPrimary onClick={onConfirm}>
                {t("root.make_withdrawal")}
              </DsButtonPrimary>
            )}
            <DsButtonSecondary onClick={close}>
              {t("root.close")}
            </DsButtonSecondary>
          </DsStackVertical>
        </DsMediaQuery>
      </DsBox>
    </Sheet>
  )
}

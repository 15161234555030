import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { PostType } from "../../types/post"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"
import { parsePost } from "../../utils/parsePost"

export async function postById(id: string) {
  const userId = (await getUserId()) as string

  const { data } = await supabase
    .from("db")
    .select(
      `
        id::text, 
        data, 
        member(*),
        user_uuid,
        saved:db!parent_id(id::text),
        liked:db!parent_id(id::text),
        likesCount:db!parent_id(count),
        commentsCount:db!parent_id(count),
        channel:parent_id(
          id::text,
          kind,
          data
        )
      `
    )
    .eq("tenant", getTenant())
    .in("kind", [KINDS.POST_FIXED, KINDS.POST])
    .eq("saved.kind", KINDS.POST_SAVED)
    .eq("liked.kind", KINDS.POST_LIKE)
    .eq("likesCount.kind", KINDS.POST_LIKE)
    .eq("commentsCount.kind", KINDS.POST_COMMENT)
    .eq("channel.kind", KINDS.CHANNEL)
    .eq("liked.user_uuid", userId)
    .eq("id", id)
    .limit(1)
    .single()

  return data ? parsePost(data as unknown as PostType) : null
}

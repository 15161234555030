import { memo, useEffect } from "react"
import { useMatch } from "react-router-dom"
import { DsNavLink, DsNavLinkProps } from "@raisesistemas/ds"
import { useNav } from "../../hooks/useNav"

type NavLinkProps = Omit<DsNavLinkProps, "isActive" | "label" | "component"> & {
  title: string
  to: string
  onActiveChange?: (title: string, icon: string) => void
}

export const NavLink = memo((props: NavLinkProps) => {
  const { title, icon, to, onClick, rightSection, onActiveChange } = props
  const match = useMatch(to)
  const navigate = useNav()
  const isActive = Boolean(match)

  const handleNavigate = () => {
    if (typeof onClick === "function") {
      return onClick()
    }
    navigate(to)
  }

  useEffect(() => {
    if (isActive && onActiveChange) {
      onActiveChange(title, icon as string)
    }
  }, [title, icon, onActiveChange])

  return (
    <DsNavLink
      component="div"
      isActive={isActive}
      label={title}
      icon={icon}
      onClick={handleNavigate}
      rightSection={rightSection}
    />
  )
})

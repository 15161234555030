import {
  DsActionIconDefault,
  DsFlex,
  DsGroup,
  DsMenu,
  DsScrollArea,
  DsStackVertical,
  DsText,
  DsThemeIcon,
  useDsTheme,
  DsContainer,
  createStyles,
  rem
} from "@raisesistemas/ds"
import { Topic } from "./Topic"
import { NavLink } from "../../Nav/NavLink"
import { useMemo } from "react"
import { drawers } from "../../../state"
import { ArrowsMove } from "../../../icons"
import { useChannelLockIcon } from "../../../hooks/useChannelLockIcon"
import { NestedBoard } from "../../DndList/NestedBoard"
import { ContentSkeleton } from "../../Skeletons/ContentSkeleton"
import { useTopicMove } from "../../../hooks/useTopicMove"
import { useCurrentChannel } from "../../../hooks/useCurrentChannel"
import { useIsRole } from "../../../hooks/useIsRole"
import { useUserPermissions } from "../../../hooks/useUserPermissions"
import { useTranslation } from "react-i18next"
import { IconPlus } from "@tabler/icons-react"
import { useActivityUnreadCountQuery } from "../../../hooks/queries/useActivityUnreadCountQuery"
import { ChannelType } from "../../../types/channel"
import { DndList } from "../../DndList"

type ChannelTopicsProps = {
  hiddeChannelsHeader?: boolean
}
export const ChannelTopics = (props: ChannelTopicsProps) => {
  const { hiddeChannelsHeader } = props

  const { classes, cx } = useStyles()
  const { t } = useTranslation()
  const permissions = useUserPermissions()
  const { getChannelLockIcon } = useChannelLockIcon()
  const { fn } = useDsTheme()
  const {
    topics,
    move,
    moveForAnotherTopic,
    openModalTopicDelete
  } = useTopicMove()
  const { isOwner, isModerator } = useIsRole()
  const dragAndDropPermitted = useMemo(() => isOwner || isModerator, [
    isOwner,
    isModerator
  ])
  const { data: unreadActivities } = useActivityUnreadCountQuery()
  const { handleChangeActive } = useCurrentChannel()

  const renderBadge = (channelId: string) => {
    if (!unreadActivities) return null

    const currentChannel = unreadActivities.channels.find(
      ({ id }) => id === channelId
    )

    if (!currentChannel) return null

    return (
      <DsThemeIcon size={24} radius={12}>
        <DsText variant="body-3" weight="medium">
          {currentChannel.count}
        </DsText>
      </DsThemeIcon>
    )
  }

  const renderDragAndDropIndicator = () => {
    return (
      <ArrowsMove
        size={12}
        color={fn.lighten(fn.primaryColor(), 0.2)}
        className={cx(classes.arrows, {
          indicator: dragAndDropPermitted
        })}
      />
    )
  }

  const renderRightSection = (channel: ChannelType) => {
    return (
      <DsFlex align="center" gap={8}>
        {renderDragAndDropIndicator()}
        {getChannelLockIcon(channel)}
        {renderBadge(channel.id)}
      </DsFlex>
    )
  }

  if (!topics)
    return (
      <DsContainer className={classes.rootSkeleton}>
        <ContentSkeleton quantity={5} />
      </DsContainer>
    )

  return (
    <DsStackVertical spacing={0} className={classes.root}>
      {!hiddeChannelsHeader && (
        <DsGroup className={classes.header} position="apart">
          <DsText variant="body-1" weight="semi-bold">
            {t("root.community_channels")}
          </DsText>
          {permissions.CAN_SEE_NEW_CHANNEL_BUTTON && (
            <DsMenu
              target={
                <div>
                  <DsActionIconDefault radius="lg" size="sm">
                    <IconPlus size={12} />
                  </DsActionIconDefault>
                </div>
              }
              dropdownItens={[
                {
                  name: t("button.create_channel"),
                  icon: " ",
                  withDivider: true,
                  visible: true,
                  onClick: drawers.channel.create.open
                },
                {
                  name: t("button.create_topic"),
                  icon: " ",
                  withDivider: false,
                  visible: true,
                  onClick: drawers.topic.create.open
                }
              ]}
            />
          )}
        </DsGroup>
      )}

      <DsScrollArea className={classes.scroll}>
        <DsStackVertical className={classes.rootDnd}>
          <DndList
            data={topics}
            positionExtractor={topic => topic.sort.position}
            extractSubItems={topic => topic.channels}
            subItemKey="channels"
            onItemMove={move}
            onSubItemMoveInSameBoard={move}
            onSubItemMoveForAnotherBoard={moveForAnotherTopic}
            isDragDisabled={!dragAndDropPermitted}
            disableDropInIndex={[0]}
            renderItem={topic => (
              <Topic
                id={topic.id}
                key={topic.id}
                title={topic.data.title}
                channels={topic.channels}
                openChannelDrawer={drawers.channel.create.open}
                openTopicCreateDrawer={drawers.topic.create.open}
                openTopicEditDrawer={drawers.topic.edit.open}
                openModalTopicDelete={openModalTopicDelete}
              >
                <NestedBoard
                  data={topic.channels}
                  itemId={topic.id}
                  isDragDisabled={!dragAndDropPermitted}
                  renderSubItem={(channel, snapshot) => (
                    <div
                      className={cx(classes.main, {
                        [classes.mainIsDragging]: snapshot?.isDragging
                      })}
                    >
                      <NavLink
                        to={`/channels/${channel.id}/posts`}
                        title={channel.data.title}
                        icon={channel.data.emoji}
                        rightSection={renderRightSection(channel)}
                        onActiveChange={() => handleChangeActive(channel)}
                      />
                    </div>
                  )}
                />
              </Topic>
            )}
          />
        </DsStackVertical>
      </DsScrollArea>
    </DsStackVertical>
  )
}

const useStyles = createStyles(({ spacing, colorScheme, fn }) => ({
  root: {
    height: "100%",
    maxHeight: " 100%",
    overflowY: "scroll",
    "::-webkit-scrollbar": { display: "none" },
    msOverflowStyle: "none",
    scrollbarWidth: "none"
  },
  rootSkeleton: {
    flex: 1,
    maxWidth: "100%",
    height: "100%",
    width: "100%"
  },
  scroll: {
    flex: 1
  },
  rootDnd: {
    flex: 1
  },
  header: {
    padding: `8px ${spacing.xs}`
  },
  main: {
    borderRadius: rem(4),
    position: "relative",
    "&:hover .indicator": {
      opacity: 1
    }
  },
  mainIsDragging: {
    background:
      colorScheme === "dark"
        ? fn.rgba(fn.primaryColor(), 0.2)
        : fn.lighten(fn.primaryColor(), 0.95)
  },
  arrows: {
    cursor: "move",
    opacity: 0
  },
  modalTitle: {
    display: "inline"
  }
}))

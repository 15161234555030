export const STORAGE_KEYS = {
  USER_ID: "@luminy/user-id",
  TENANT_ID: "@luminy/tenant-id",
  PRIMARY_COLOR: "@luminy/primary-color",
  PUSH_TOKEN: "@luminy/push-key",
  BALANCE_AVAILABLE_TYPE: "@luminy/balance-available-type",
  BALANCE_PROCESSING_TYPE: "@luminy/balance-processing-type",
  ACTIVATED_AT: "@luminy/activated_at",
  EVENT_CREATE_DATA: "@luminy/create-event",
  LANGUAGE: "@luminy/language"
}

export const ROLES = {
  OWNER: "owner",
  MEMBER: "member",
  MEMBER_PRO: "member_pro",
  MODERATOR: "moderator",
  BLOCKED_MEMBER: "blocked_member"
}

export const DEFAULT_SPACE_LOGO =
  "https://eszqhetmdvzsjnosviko.supabase.co/storage/v1/object/public/avatars/dev/1688048037775688729.png"

export const KINDS = {
  MEMBER_CONFIG: "member_config",
  POST_COMMENT: "post_comment",
  COMMENT_LIKE: "comment_like",
  COMMENT_REPLY: "comment_reply",
  POST: "post",
  POST_FIXED: "post_fixed",
  POST_LIKE: "post_like",
  POST_SAVED: "post_saved",
  POST_SAVED_TRASH: "trash_post_saved",
  SPACE: "space",
  SPACE_PRO: "space_pro",
  REPLY_LIKE: "reply_like",
  SPACE_CONFIG: "space_config",
  CHANNEL: "channel",
  CHANNEL_MEMBER: "channel_member",
  TOPIC: "topic",
  TOPIC_TRASH: "trash_topic"
}

export const CONTACTS = {
  PHONE: "+5513997752818",
  EMAIL: "info@luminy.app"
}

export const WHATSAPP_LINK = `https://wa.me/${CONTACTS.PHONE}`

export const IS_PRODUCTION =
  import.meta.env.PROD && window.location.host.split(".").length === 3

const DEBUG_RESOURCES_ENABLED = false
export const HIDE_DEBUG_RESOURCES = IS_PRODUCTION || !DEBUG_RESOURCES_ENABLED

export const HOSTS_DEV = ["localhost", "lvh.me", "127.0.0.1", "onrender.com"]

export const MAIN_HOST = "luminy.app"

export const FEE_PERCENTAGE = 5
export const FEE_IN_DECIMAL = FEE_PERCENTAGE / 100
export const MIN_WITHDRAWAL_AMOUNT_IN_CENTS = 1000

export const DEFAULT_AVATAR_OPTIONS = {
  maxHeight: 200,
  maxWidth: 200,
  quality: 0.8
}

export const DEFAULT_POST_THUMBNAIL = {
  maxHeight: 300,
  maxWidth: 840,
  quality: 0.8
}

import {
  DragDropContext,
  DraggableLocation,
  Droppable,
  OnDragEndResponder
} from "react-beautiful-dnd"

import { ItemDraggable } from "./ItemDraggable"
import { usePosition } from "../../hooks/usePosition"
import { useOrderArrayBy } from "../../hooks/useOrderArray"
import { Ordenation, useOrdenation } from "../../hooks/useOrdenantion"
import { incrementDecrement } from "../../utils/incrementDecrement"
import { useReorderArray } from "../../hooks/useReorderArray"

export const TYPES = {
  ITEM: "ITEM",
  SUB_ITEM: "SUB_ITEM"
}

export const subItemGenDroppableId = (id: string) => "droppable-sub-items-" + id

const move = <T,>(
  source: T,
  destination: T,
  sourceIndex: number,
  destinationIndex: number,
  extractorSubItems: ExtractSubItems<T>
) => {
  const sourceClone = Object.assign({}, source)
  const destinationClone = Object.assign({}, destination)

  const [moved] = extractorSubItems(sourceClone).splice(sourceIndex, 1)

  extractorSubItems(destinationClone).splice(destinationIndex, 0, moved)

  return {
    sourceReordered: sourceClone,
    destinationReordered: destinationClone
  }
}

type Item<T> = T & {
  id: string
}

type ExtractSubItems<Type> = (item: Type) => any[]

type BoardProps<Type> = {
  data: Item<Type>[]
  renderItem: (item: Item<Type>, index: number) => React.ReactNode
  subItemKey: keyof Item<Type>
  extractSubItems: ExtractSubItems<Item<Type>>
  isDragDisabled: boolean

  onItemMove?: (
    item: Item<Type>,
    prevPosition: number,
    nextPosition: number
  ) => void
  onSubItemMoveInSameBoard?: (
    subItem: any,
    prevPosition: number,
    nextPosition: number
  ) => void
  onSubItemMoveForAnotherBoard?: (
    subItem: any,
    prevPosition: number,
    nextPosition: number,
    item: Item<Type>
  ) => void

  disableDropInIndex?: number[]
  ordernation?: Ordenation
  positionExtractor: (item: Item<Type>) => number
  variantIncrement?: number
}

export const Board = <Type extends unknown>(props: BoardProps<Type>) => {
  const { isAsc, isDesc } = useOrdenation(props.ordernation || "DESC")
  const { reorder } = useReorderArray()

  const { getPrevPosition, getNextPosition } = usePosition({
    isAsc,
    extractSubItems: props.extractSubItems,
    incrementNumber: props.variantIncrement || 1000,
    positionExtractor: props.positionExtractor,
    subItemKey: props.subItemKey
  })

  const { items, updateItems } = useOrderArrayBy(props.data, {
    isDesc,
    positionExtractor: props.positionExtractor,
    extractSubItems: props.extractSubItems,
    subItemKey: props.subItemKey
  })

  const getItem = (droppableId: string) =>
    items.find(item => subItemGenDroppableId(item.id) === droppableId)

  const isItemMovement = (type: string) => type === TYPES.ITEM

  const isSubItemMovementInSameBoard = (
    source: DraggableLocation,
    destination: DraggableLocation
  ) => source.droppableId === destination.droppableId

  const isSubItemMovementInDiffBoard = (
    source: DraggableLocation,
    destination: DraggableLocation
  ) => source.droppableId !== destination.droppableId

  const reorderItems = (
    source: DraggableLocation,
    destination: DraggableLocation
  ) => {
    const prevPosition = getPrevPosition(source.index, destination.index, items)

    const nextPosition = getNextPosition(source.index, destination.index, items)

    const sourceItem = items[source.index]
    const itemsReordered = reorder(items, source.index, destination.index)
    props.onItemMove?.(sourceItem, prevPosition, nextPosition)
    updateItems(itemsReordered)
  }

  const reorderSubItemsInSameBoard = (
    source: DraggableLocation,
    destination: DraggableLocation
  ) => {
    const item = getItem(source.droppableId)

    if (!item) return

    const subItems = props.extractSubItems?.(item)
    const sourceItem = subItems[source.index]

    const prevPosition = getPrevPosition(
      source.index,
      destination.index,
      subItems
    )

    const nextPosition = getNextPosition(
      source.index,
      destination.index,
      subItems
    )

    const subItemReordered = reorder(
      props.extractSubItems?.(item),
      source.index,
      destination.index
    )
    props.onSubItemMoveInSameBoard?.(sourceItem, prevPosition, nextPosition)

    const itemsReordered = items.map(_item => ({
      ..._item,
      [props.subItemKey]:
        _item.id === item.id ? subItemReordered : _item[props.subItemKey]
    }))

    updateItems(itemsReordered)
  }

  const reorderSubItemsInDiffBoards = (
    source: DraggableLocation,
    destination: DraggableLocation
  ) => {
    const sourceItem = getItem(source.droppableId)
    const destinationItem = getItem(destination.droppableId)
    if (!sourceItem || !destinationItem || !props.extractSubItems) return

    const destinationSubItems = props.extractSubItems?.(destinationItem)
    const sourceSubItens = props.extractSubItems?.(sourceItem)

    const subItem = sourceSubItens[source.index]

    const sourceIndex = incrementDecrement(isDesc, destination.index)

    const prevPosition = getPrevPosition(
      sourceIndex,
      destination.index,
      destinationSubItems
    )

    const nextPosition = getNextPosition(
      sourceIndex,
      destination.index,
      destinationSubItems
    )

    const { sourceReordered, destinationReordered } = move<Item<Type>>(
      sourceItem,
      destinationItem,
      source.index,
      destination.index,
      props.extractSubItems
    )

    props.onSubItemMoveForAnotherBoard?.(
      subItem,
      prevPosition,
      nextPosition,
      destinationItem
    )

    const itemsReordered = items.map(item => {
      if (sourceReordered.id === item.id) return sourceReordered
      if (destinationReordered.id === item.id) return destinationReordered

      return item
    })

    updateItems(itemsReordered)
  }

  const onDragEnd: OnDragEndResponder = result => {
    const { source, destination, type } = result

    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return
    }

    if (isItemMovement(type)) return reorderItems(source, destination)

    if (isSubItemMovementInSameBoard(source, destination))
      return reorderSubItemsInSameBoard(source, destination)

    if (isSubItemMovementInDiffBoard(source, destination))
      return reorderSubItemsInDiffBoards(source, destination)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="BOARD" type={TYPES.ITEM}>
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => {
              if (props.disableDropInIndex?.includes(index))
                return props.renderItem(item, index)
              return (
                <ItemDraggable
                  index={index}
                  key={item.id}
                  isDragDisabled={props.isDragDisabled}
                  draggableId={item.id.toString()}
                >
                  {() => props.renderItem(item, index)}
                </ItemDraggable>
              )
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export type Ordenation = "ASC" | "DESC"

export const useOrdenation = (ordernation: Ordenation) => {
  const isAsc = ordernation === "ASC"
  const isDesc = ordernation === "DESC"

  return {
    isAsc,
    isDesc
  }
}

export const msHandleErrorMessage = (responseError: any) => {
  const error = responseError.data.errors.base
  const hasError = Boolean(error)
  const isSingleMessage = hasError && typeof error === "string"
  const isArrayMessage = hasError && Array.isArray(error)

  if (isSingleMessage || isArrayMessage) {
    const message = isSingleMessage ? error : isArrayMessage && error.pop()
    return message
  }

  return null
}

import { useEffect, useState } from "react"

type OrderArrayBy<Item> = {
  positionExtractor: (item: Item) => number
  extractSubItems: <K>(item: Item) => K[]
  subItemKey: keyof Item
  isDesc: boolean
}
export const useOrderArrayBy = <T>(data: T[], options: OrderArrayBy<T>) => {
  const [items, setItems] = useState(data)

  const orderItemBy = (data: any[]) => {
    let listOrderdened = Array.from(data)

    if (options.positionExtractor) {
      listOrderdened = listOrderdened.sort(
        (dataA, dataB) =>
          options.positionExtractor(dataA) - options.positionExtractor(dataB)
      )
    }

    if (options.isDesc) listOrderdened = listOrderdened.reverse()

    return listOrderdened
  }

  const orderSubItemBy = (data: T[]) => {
    return data.map(item => {
      const clonedItem = Object.assign({}, item)
      const subItems = options.extractSubItems(clonedItem)
      const subItemsOrdered = orderItemBy(subItems)

      return {
        ...clonedItem,
        [options.subItemKey]: subItemsOrdered
      }
    })
  }

  useEffect(() => {
    if (data) {
      setItems(orderItemBy(orderSubItemBy(data)))
    }
  }, [data])

  return {
    items,
    updateItems: setItems
  }
}

import { useTranslation } from "react-i18next"
import { BalanceHeading } from "./BalanceHeading"
import { BalanceValue } from "./BalanceValue"
import {
  DsBox,
  DsButtonPrimary,
  DsGroup,
  DsStackVertical
} from "@raisesistemas/ds"
import { useBalanceQueries } from "../../hooks/queries/useBalanceQueries"
import { BalanceSkeleton } from "../Skeletons/BalanceSkeleton"
import { formatMoneyInCents } from "../../utils/formatMoneyInCents"
import { STORAGE_KEYS } from "../../lib/constants"
import { drawers } from "../../state"

type BalanceDisplayProps = {
  showButtonHeading?: boolean | null
  showSmartButton?: boolean | null
  direction?: "column" | "row"
}

export const BalanceDisplay: React.FC<BalanceDisplayProps> = props => {
  const {
    showButtonHeading = false,
    showSmartButton = false,
    direction = "column"
  } = props

  const { t } = useTranslation()
  const balanceQueries = useBalanceQueries()
  const {
    balance,
    hasProcessingWithdraw,
    balanceUpdatedAt,
    balanceLastWithdrawUpdatedAt
  } = balanceQueries.balanceOwner()

  const openExtractDrawer = () => drawers.extract.full.open()

  if (!balance) return <BalanceSkeleton />

  return (
    <DsStackVertical>
      {showButtonHeading && hasProcessingWithdraw && (
        <DsGroup position="right">
          <DsButtonPrimary onClick={openExtractDrawer}>
            {t("root.see_extract")}
          </DsButtonPrimary>
        </DsGroup>
      )}
      <DsGroup noWrap={direction == "row"}>
        <DsBox style={{ width: "100%" }}>
          <DsStackVertical styles={{ width: "100%" }}>
            <BalanceHeading
              title={t("root.balance_available")}
              tooltipTitle={t("tooltip.balance_available")}
            >
              {showSmartButton && !hasProcessingWithdraw && (
                <DsButtonPrimary onClick={openExtractDrawer}>
                  {t("root.see_extract")}
                </DsButtonPrimary>
              )}
            </BalanceHeading>

            <BalanceValue
              value={formatMoneyInCents(balance.balance_cents)}
              updatedAt={balanceUpdatedAt}
              storageKey={STORAGE_KEYS.BALANCE_AVAILABLE_TYPE}
            />
          </DsStackVertical>
        </DsBox>

        {hasProcessingWithdraw && balance.last_withdraw && (
          <DsBox style={{ width: "100%" }}>
            <DsStackVertical>
              <BalanceHeading
                title={t("root.withdrawal_in_processing")}
                tooltipTitle={t("tooltip.withdrawal_in_processing")}
              />

              <BalanceValue
                value={formatMoneyInCents(balance.last_withdraw.value_cents)}
                storageKey={STORAGE_KEYS.BALANCE_PROCESSING_TYPE}
                updatedAt={balanceLastWithdrawUpdatedAt}
              />
            </DsStackVertical>
          </DsBox>
        )}
      </DsGroup>
    </DsStackVertical>
  )
}

type PositionProps<Item> = {
  positionExtractor: (item: Item) => number
  incrementNumber: number
  extractSubItems: (item: Item) => any[]
  subItemKey: keyof Item

  isAsc: boolean
}

export const usePosition = <Type>(props: PositionProps<Type>) => {
  const isUpFromDown = (fromIndex: number, toIndex: number) =>
    fromIndex < toIndex

  const getPrevPosition = (
    fromIndex: number,
    toIndex: number,
    list: Type[]
  ) => {
    if (!props.positionExtractor) return toIndex

    const prevIndex = isUpFromDown(fromIndex, toIndex) ? toIndex : toIndex - 1

    const prevItem = list[prevIndex]

    if (prevItem) return props.positionExtractor(prevItem)

    if (props.isAsc) return 0

    if (!list[0]) return props.incrementNumber

    return props.positionExtractor(list[0]) + props.incrementNumber
  }

  const getNextPosition = (
    fromIndex: number,
    toIndex: number,
    list: Type[]
  ) => {
    if (!props.positionExtractor) return toIndex

    const nextIndex = isUpFromDown(fromIndex, toIndex) ? toIndex + 1 : toIndex

    const nextItem = list[nextIndex]

    if (nextItem) return props.positionExtractor(nextItem)

    if (!list[list.length - 1]) return props.incrementNumber

    if (props.isAsc)
      return (
        props.positionExtractor(list[list.length - 1]) + props.incrementNumber
      )
    return 0
  }

  return {
    getPrevPosition,
    getNextPosition
  }
}

import {
  DsButtonDestructive,
  DsButtonSecondary,
  DsGroup,
  DsModal,
  DsModalProps,
  DsText,
  createStyles
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"

type ConfirmDeleteModalProps = Pick<DsModalProps, "onClose" | "opened"> & {
  title: string | React.ReactNode
  description: string
  isLoading: boolean
  onDelete: () => void
}

export const ConfirmDeleteModal = (props: ConfirmDeleteModalProps) => {
  const { title, description, isLoading, onDelete, onClose, ...rest } = props

  const { t } = useTranslation()
  const { classes } = useStyles()

  const handleDelete = () => {
    onDelete()
    onClose()
  }
  return (
    <DsModal
      title={title}
      onClose={onClose}
      main={
        <DsText variant="body-2" weight="regular">
          {description}
        </DsText>
      }
      footer={
        <DsGroup position="right" noWrap>
          <DsButtonSecondary onClick={onClose} className={classes.button}>
            {t("button.cancel")}
          </DsButtonSecondary>
          <DsButtonDestructive
            loading={isLoading}
            onClick={handleDelete}
            className={classes.button}
          >
            {t("button.delete")}
          </DsButtonDestructive>
        </DsGroup>
      }
      {...rest}
    />
  )
}

const useStyles = createStyles(() => ({
  button: {
    width: "100%"
  }
}))

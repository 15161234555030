import { JSONContent } from "@tiptap/core"
import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"

export type PostCreatePayload = {
  thumbnail: string
  title: string
  content: JSONContent
  user_uuid: string
  channelId: string
}

export async function postCreate(payload: PostCreatePayload) {
  const tenant = getTenant()

  const recordPost = {
    tenant,
    kind: KINDS.POST,
    parent_id: payload.channelId,
    data: {
      title: payload.title,
      thumbnail: payload.thumbnail,
      content: payload.content
    },
    user_uuid: payload.user_uuid
  }

  const { error } = await supabase.from("db").insert(recordPost)

  if (error) throw error
}

import { AspectRatio } from "@mantine/core"
import { DsImage, createStyles } from "@raisesistemas/ds"
import { memo } from "react"

type PostThumbnailProps = {
  thumbnail?: string
}

export const PostThumbnail = memo((props: PostThumbnailProps) => {
  const { thumbnail } = props
  const { classes } = useStyles()

  if (!thumbnail) return
  return (
    <AspectRatio ratio={2.8}>
      <DsImage
        width="auto"
        height="auto"
        classNames={{
          figure: classes.thumbnailFigure,
          image: classes.thumbnailImage
        }}
        src={thumbnail}
      />
    </AspectRatio>
  )
})

const useStyles = createStyles({
  thumbnailFigure: {
    width: "100%"
  },
  thumbnailImage: {
    objectFit: "cover"
  }
})

import { DraggableStateSnapshot, Droppable } from "react-beautiful-dnd"
import { TYPES, subItemGenDroppableId } from "./Board"
import { ItemDraggable } from "./ItemDraggable"
import { rem } from "@raisesistemas/ds"

type Item<T> = T & {
  id: string
}

export type SubItemBoardProps<Type> = {
  data: Item<Type>[]
  itemId: string
  renderSubItem: (
    item: Item<Type>,
    snapshot?: DraggableStateSnapshot
  ) => React.ReactNode
  isDragDisabled: boolean
}

export const NestedBoard = <Type extends unknown>(
  props: SubItemBoardProps<Type>
) => {
  return (
    <Droppable
      type={TYPES.SUB_ITEM}
      droppableId={subItemGenDroppableId(props.itemId)}
    >
      {provided => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{ minHeight: rem(5) }}
        >
          {props.data.map((subItem, index) => (
            <ItemDraggable
              key={subItem.id}
              index={index}
              draggableId={subItemGenDroppableId(subItem.id)}
              isDragDisabled={props.isDragDisabled}
            >
              {snapshot => props.renderSubItem(subItem, snapshot)}
            </ItemDraggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}
